.arrow-link {
  position: relative;
  padding-left: 1.25rem;
  display: block !important;
  color: $color-grey-dark;
  font-size: 16px;
  font-family: $nngrotesk-text-heading;
  font-weight: 700;
  line-height: 20px;

  &:before {
    content: "";
    font-family: icomoon !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 0.55em;
    color: #ee7f00;
    transition: left .5s ease-in-out;
    line-height: 1;
    transform: translateY(-50%);
  }

  &:hover:before,
  &:focus:before {
    left: .375rem
  }
}
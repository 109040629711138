.spacer-badge {
  width: 100%;
  justify-content: center;
  overflow: hidden;

  .badge-inner {
    position: relative;
    padding: 8px 16px;
    border-radius: 50px;
    color: $color-orange-dark-new;
    background-color: $color-orange-00;
    font-size: 12px;
    font-family: $nndagny-display-normal;
    text-transform: uppercase;

    &:before, &:after {
      content: "";
      width: 800px;
      height: 1px;
      background-color: $color-grey-white;
      position: absolute;
      right: calc(100% + 8px);
      top: 50%;
    }

    &:before {
      right: calc(100% + 8px);
    }

    &:after {
      left: calc(100% + 8px);
    }
  }

  &.no-line {
    justify-content: flex-start;

    .badge-inner {
      &:before, &:after {
        display: none;
      }
    }
  }

  &.no-formatitng {    
    .badge-inner {
      text-transform: none;
    }
  }
}
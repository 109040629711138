.equation {
  background-color: #F7F7F7;
  padding: 24px 6px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include breakpoint(375px down) {
    * {
      font-size: 0.875rem;
    }
  }

  @include breakpoint(medium up) {
    row-gap: 48px;
    padding: 24px;
  }

  .equation-text {
    text-align: center;

    p {
      text-align: center;
    }
  }

  .equation-main {
    display: flex;
    column-gap: 24px;
    align-items: center;

    @include breakpoint(375px down) {
      column-gap: 12px;
    }
  }

  .equation-left {
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;

    hr {
      margin: 0;
      padding: 0;
      background-color: $color-gray-light;
    }
  }

  .equation-multiplier-sign {
    font-size: 1.75rem;
    font-family: $nndagny-display-normal;

    @include breakpoint(375px down) {
      font-size: 1.25rem;
    }
  }

  .equation-right {
    flex: 4;
  }
}
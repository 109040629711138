@import "design-system/common/variables";

#design-system {
  // Foundation
  @import "design-system/_foundation/foundation";

  @include foundation-flex-grid;
  @include foundation-flex-classes;
  @include foundation-visibility-classes;

  // Variables
  @import "design-system/common/colors";

  // Common
  @import "design-system/common/base";
  @import "design-system/common/accessibility";
  @import "design-system/common/typography";
  @import "design-system/common/mixins";

  // Utilities
  @import "design-system/utilities/utilities";
  @import "design-system/utilities/general";

  // Swiper
  @import "../../../node_modules/swiper/swiper";

  // Components DS
  @import "design-system/components-ds/attachment";
  @import "design-system/components-ds/cards/card-banner";
  @import "design-system/components-ds/cards/card-gift";
  @import "design-system/components-ds/cards/card-icon";
  @import "design-system/components-ds/cards/card-icon-shift";
  @import "design-system/components-ds/cards/card-quote";
  @import "design-system/components-ds/cards/card-photo-link";
  @import "design-system/components-ds/cards/cards-two-columns";
  @import "design-system/components-ds/accordion";
  @import "design-system/components-ds/badges";
  @import "design-system/components-ds/breadcrumbs";
  @import "design-system/components-ds/button";
  @import "design-system/components-ds/button-file";
  @import "design-system/components-ds/button-copy";
  @import "design-system/components-ds/button-scroll";
  @import "design-system/components-ds/button-with-login";
  @import "design-system/components-ds/buttons-group";
  @import "design-system/components-ds/callout";
  @import "design-system/components-ds/container";
  @import "design-system/components-ds/container-two-columns";
  @import "design-system/components-ds/container-multi-columns";
  @import "design-system/components-ds/container-flex-columns";
  @import "design-system/components-ds/counter";
  @import "design-system/components-ds/disclaimer";
  @import "design-system/components-ds/documents";
  @import "design-system/components-ds/hotline";
  @import "design-system/components-ds/full-photo";
  @import "design-system/components-ds/gift-with-counter";
  @import "design-system/components-ds/header";
  @import "design-system/components-ds/hero";
  @import "design-system/components-ds/hero-two-columns";
  @import "design-system/components-ds/hero-single-column";
  @import "design-system/components-ds/icon-simple";
  @import "design-system/components-ds/info-box";
  @import "design-system/components-ds/list-simple";
  @import "design-system/components-ds/list-with-icons";
  @import "design-system/components-ds/price-box";
  @import "design-system/components-ds/quiz";
  @import "design-system/components-ds/pagination";
  @import "design-system/components-ds/side-photo";
  @import "design-system/components-ds/side-icon";
  @import "design-system/components-ds/slider-icon";
  @import "design-system/components-ds/slider-icon-shift";
  @import "design-system/components-ds/spacer";
  @import "design-system/components-ds/spacer-badge";
  @import "design-system/components-ds/spacer-more";
  @import "design-system/components-ds/text-simple";
  @import "design-system/components-ds/image-simple";
  @import "design-system/components-ds/financial-results";
  @import "design-system/components-ds/callout-banner";
  @import "design-system/components-ds/ofe-table";
  @import "design-system/components-ds/equation";
  @import "design-system/components-ds/ofe-map";
  @import "design-system/components-ds/arrow-link";
  @import "design-system/components-ds/form";
  @import "design-system/components-ds/online-test";  
  @import "design-system/components-ds/lazy-youtube";
  @import "design-system/components-ds/opinions-slider";
  @import "design-system/components-ds/video-simple";
  @import "design-system/components-ds/questionnaire";
  @import "design-system/components-ds/text-box";
  @import "design-system/components-ds/nn-widget";
  @import "design-system/components-ds/ike-ikze-table";
  @import "design-system/components-ds/table-of-contents";
  @import "design-system/components-ds/superbrands-tabs";

  // overwrite styles when using old components
  @import "design-system/components-old/blog-section";
  // @import "design-system/components-old/insurance-offers"; // rewrited to card-photo-link

  background-color: $color-off-white;
  color: $color-grey-dark;
  font-family: $nndagny-text-regular;
  font-size: 16px;
  line-height: 1.25;
  text-align: left;

  .row {
    max-width: 93.5rem;
  }

  .columns {
    @media (min-width: 1365px) {
      padding-right: 2.040625rem;
      padding-left: 2.040625rem;
    }
  }

  &:has(>.row.columns>.breadcrumbs-list) {
    padding-top: 0 !important;
  }
}

.two-columns-ds {
  @media (max-width: 1023px) {
    footer .sitemap {
      padding: 5.625rem 1.25rem 0 !important;
    }
  }

  @media (min-width: 1024px) {
    footer .sitemap {
      padding: 6.375rem 0 0 !important;
    }
  }

}

@import "design-system/layout/footer-lp";
@import "design-system/layout/navigation-lp";

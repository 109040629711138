.financial-results {
  color: $color-grey-dark;

  .results-info {
    margin-bottom: 8px;
    font-family: $nndagny-display-normal;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4 
  }

  .results {
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch; 
    border-radius: 6px;
    background: $color-off-white;
  }

  .results-data {
    font-family: $nndagny-display-normal;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.1; 
  }

  .results-desc {
    font-family: $nndagny-text-regular;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;  
  }
}
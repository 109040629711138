.table-of-contents {
    display: flex;
    gap: .75rem;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    @include breakpoint(medium down) {
        display: none;
    }

    .contents-item {
        height: 2rem;
        padding: 0 .75rem;
        margin: 0;
        border: 1px solid #E0E0E0;
        border-radius: 1rem;
        line-height: calc(2rem - 2px);
        font-size: .75rem;

        &::before {
            display: none;
        }

        a {
            color: #404040;
            font-weight: 400;
        }

        &:hover {
            border-color: #EA650D;
            box-shadow: $btn-active-shadow;

            a {
                color: #404040;
            }
        }
    }
}
.card-photo-link {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 2px 6px 0px rgba(65, 65, 65, 0.1);

  .top-photo {
    height: 240px;
    background-image: var(--top-photo);
    background-size: cover;
    background-position: center right;

    @include breakpoint(large) {
      height: 280px;
    }
  }

  .card-items {
    // height: calc(100% - 240px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 48px;

    @include breakpoint(large) {
      height: calc(100% - 280px);
    }

    button,
    a,
    .btn {
      width: 100% !important;
    }
  }
}

.hero-single-column {
  background-color: #F7F7F7;
  margin-bottom: 16px;

  @include breakpoint(large up) {
    width: calc(100% + 96px);
    margin-left: -48px;
  }

  & > .row {

    @media (min-width: 1200px) {
      max-width: 95.5rem;
    }

    @media (min-width: 1365px) {
      padding-right: 3.040625rem !important;
      padding-left: 3.040625rem !important;
    }

    @include breakpoint(large up) {
      background-image: var(--hero-bg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }

    @include breakpoint(large only) {
      height: 240px;
    }

    @include breakpoint(xlarge up) {
      height: 480px;
    }
  }

  &.small-size {    
    margin-bottom: 0;
    
    @include breakpoint(large up) {
      width: 100%;
      margin-left: 0;
    }

    & > .row {
    
      @media (min-width: 1200px) {
        max-width: 95.5rem;
      }

      @media (min-width: 1365px) {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
        margin: 0 !important;
      }


      @include breakpoint(large only) {
        height: 240px;
      }

      @include breakpoint(xlarge up) {
        height: 330px;
      }
      
    }

    .hero-image-mobile {
      @include breakpoint(medium down) {
        background-image: var(--hero-bg-mobile);    
        height: 240px;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        border-radius: 3px;
      }
    }

    & + .hero-single-column-masonry,
    & + .hero-single-column-css {
      .row {
        @include breakpoint(large up) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .callout {
        @include breakpoint(large up) {
          padding: 16px 32px;
        }
      }

      .columns-container {    
        @include breakpoint(large up) {
          transform: translateY(0);
          margin-top: -102px;
        }
      }
    }
  }


  .hero-image-mobile {
    @include breakpoint(medium down) {
      height: 240px;
    }

  }
}

.hero-single-column-masonry {

  .columns-container {

    @include breakpoint(large up) {
      transform: translateY(-160px);
    }
  }
}

.hero-two-columns-css {
  .column.large-6 {
    &:first-child {
      padding-right: 0.75rem;
      margin-top: -160px;
    }
    
    &:last-child {
      padding-left: 0.75rem;
      
    }  
  } 
}

.hero-two-columns-css.both-columns {
  .column.large-6 {
    margin-top: -160px;
    &:first-child {
      padding-right: 0.75rem;
    }
    
    &:last-child {
      padding-left: 0.75rem;      
    }  
  } 
}
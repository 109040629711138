.form-input {
    position: relative;

    input {
        position: absolute;
        top: -2px;
        left: 0;  
        width: 24px;
        height: 24px;
        display: block;
        opacity: 0;
    }

    input:focus-visible ~ label:before {
        outline: 2px solid #000;
    }
  
}
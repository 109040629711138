.callout-banner {
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  @include breakpoint(small only) {
    padding: 24px;
  }

  @include breakpoint(medium up) {
    padding: 32px 48px;
  }

  @include breakpoint(large up) {
    background-image: var(--desktop-photo);
    background-size: auto 100%;
    background-position: top right;
    background-repeat: no-repeat;
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-mobile-photo {
    width: 100%;
    height: auto;

    @include breakpoint(large up) {
      display: none;
    }
  }
}
.container-flex-columns {

  @include breakpoint(xxlarge up) {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    @include breakpoint(xxlarge up) {
      gap: var(--gutter-desktop);
    }
  }

  .col-wrapper {
    flex-grow: 1;

    &:first-of-type {
      margin-bottom: 16px;

      @include breakpoint(xxlarge up) {
        width: var(--left-col-width);
        max-width: var(--left-col-width);
        margin-bottom: 0;
      }
    }
  }
}
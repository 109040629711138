.blog-section {
  .section-header-wrapper {
    .section-header {
      padding: 0 24px;
      color: $color-grey-dark;
      font-family: $nndagny-display-normal;
      font-size: 28px;
      line-height: 1.28;
      text-align: left;

      @include breakpoint(medium up) {
        padding: 0 calc(48px - 0.46rem);
      }

      @media (min-width: 1365px) {
        padding: 0 calc(48px - 1.02rem);
      }

      span {
        color: $color-grey-dark !important;
        font-family: $nndagny-display-normal;
        font-size: 28px;
        line-height: 1.28;
      }
    }
  }

  .post-description {
    a {
      text-decoration: none;
      font-family: $nndagny-text-regular;
    }
  }
}

.info-box {
    display: flex;
    align-items: flex-start;
    max-width: 546px;
    margin-bottom: 16px;
    padding: 16px 24px;
    background-color: $color-snow-white;
    border-radius: 3px;

    .icon-svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}
.spacer-more {
  width: 100%;
  justify-content: center;
  overflow: hidden;

  .more-inner {
    position: relative;
    padding: 8px 16px;
    border-radius: 50px;

    &:before, &:after {
      content: "";
      width: 800px;
      height: 1px;
      background-color: $color-grey-white;
      position: absolute;
      right: calc(100% + 8px);
      top: 50%;
    }

    &:before {
      right: calc(100% + 8px);
    }

    &:after {
      left: calc(100% + 8px);
    }
  }

  .button-scroll {
    margin: 0;
  }

  .arrow {
    background-color: transparent;
  }

  .svg-icon {
    top: 0;
  }

  &.no-line {
    justify-content: flex-start;

    .more-inner {
      &:before, &:after {
        display: none;
      }
    }
  }
}
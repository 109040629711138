.quiz {

  .step {
    display:none;

    &.active {
      display: block;
    }

    [class*="section-header"] {
      margin-bottom: 16px;
    }

    .info-box {
      display: flex;
      align-items: center;
      max-width: 546px;
      margin-bottom: 16px;
      padding: 16px 24px;
      background-color: $color-snow-white;
      border-radius: 3px;

      .icon-svg {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
    }

    form {

      fieldset {

        label {
          //namieszane z globalnych styli
          height: 24px;
          width: fit-content;
          display: inline-block;
          margin-bottom: 24px;
          padding-left: 40px;
          color: $color-gray;
          font-size: 16px;
          line-height: 1.25;

          &:before {
            width: 24px;
            height: 24px;
            top: -2px;
            border: 1px solid $color-grey-light;
            transition: all .25s ease-in-out;
          }

          &:after {
            width: 16px;
            height: 16px;
            top: 2px;
            left: 4px;
          }

          &:hover {

            &:before {
              border-color: $color-orange;
              -webkit-box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
              -moz-box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
              box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
              transition: all .25s ease-in-out;
            }
          }
        }

        input[type=radio]:checked+label:before {
          border-color: $color-orange;
          -webkit-box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
          -moz-box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
          box-shadow: 0px 0px 16px -8px rgba(234, 101, 13, 1);
        }
      }
    }

    .reload-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 1.2;

      img {
        margin-right: 8px;
      }
    }

    .spacer-line {
      margin: 24px 0 16px;
    }

    .card-icon-shift {
      margin-bottom: 24px;

      @include breakpoint(large up) {
        max-width: 600px;
      }
    }
  }
}
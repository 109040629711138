.hotline {
  span {
    display: inline-block;

    @include breakpoint(medium down) {
      display: block;
      margin-top: 5px;
    }

    &:nth-of-type(2) {
      @include breakpoint(large up) {
        position: relative;
        padding-left: 32px;
        padding-right: 12px;

        &:before {
          content: "";
          position: absolute;
          left: 12px;
          top: 7px;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          background-color: $color-orange-medium;
        }
      }
    }

    a {
      font-size: 16px;
    }
  }

  .hours {
  }
}

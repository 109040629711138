.video-simple {
  background-color: #fff;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .video-text {
    padding: 1rem;
  }

  .video-title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: .5rem;
  }

  .video-description {
    font-size: 1rem;
    font-weight: 400;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .video-player {
    margin: 0;
  }
}